import { dark as darkButton } from "common/elements/Button/theme";
import { dark as darkNav } from "common/widgets/Menu/theme";
import { dark as darkModal } from "common/widgets/Modal/theme";
import base from "./base";
import { darkColors } from "./colors";

const darkTheme = {
	...base,
	isDark: true,
	button: darkButton,
	colors: darkColors,
	nav: darkNav,
	modal: darkModal,
};

export default darkTheme;
