import loadable from "@loadable/component";

const Home = loadable(() => import("./components/home/provider/provider"));
const Horyxen = loadable(() => import("./components/horyxen/provider/provider"));

const routes = [
	{ path: "/", exact: true, name: "home", component: Home, title: "Xeniverse - Home of your favorite protocols." },
	{ path: "/horyxen/", exact: false, name: "horyxen", component: Horyxen, title: "Xeniverse Horyxen (HORYXEN)" },
	{ path: "/horyxen/:referrer*", exact: false, name: "horyxen", component: Horyxen, title: "Xeniverse Horyxen (HORYXEN)" },
	/*
	{ path: "/blayxen", exact: true, name: "blayxen", component: null, title: "Xeniverse Blayxen (BLAYXEN)" },
	{ path: "/froxen", exact: true, name: "froxen", component: null, title: "Xeniverse FROXEN (FROXEN)" },
	{ path: "/amaxen", exact: true, name: "amaxen", component: null, title: "Xeniverse Amaxen (AMAXEN)" },
	{ path: "/kaixen", exact: true, name: "kaixen", component: null, title: "Xeniverse Kaixen (KAIXEN)" }
	*/
];

export default routes;
