/* reducer.js */

/** External Module Dependencies **/
import { combineReducers } from "redux";

/** Internal Module Dependencies **/

const Reducer = function(def = {}, action = {}) {
	switch (action.type) {
		default:
			return def;
	}
};


export default combineReducers({ Reducer });
